import React, { Dispatch, SetStateAction, useEffect, useState } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Typography,
  Box,
  TextField,
  Grid,
  styled,
  FormControlLabel,
  Checkbox,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import { RootState } from "../../store";

import Location from "./location-edit";

interface Address {
  id: number;
  title: string;
  address: string;
  city: string;
  state: string;
  country: string;
  pincode: string;
  address_type: string;
}

interface AddressModalProps {
  open: boolean;
  setIsAddressModalOpen: Dispatch<SetStateAction<boolean>>;
  selectedAddress: any;
  setSelectedAddress: any;
  addressList: any;
  setAddressList: any;
}

interface FormData {
  title: string;
  address: string;
  city: string;
  state: string;
  country: string;
  pincode: string;
  address_type: string;
  latitude: string;
  longitude: string;
}

const ErrorLabel = styled(Typography)(() => ({
  color: "red",
  fontSize: "0.875rem", // Adjust the font size as needed
  fontWeight: 500,
  marginBottom: "0px", // Add margin as needed
  textAlign: "left",
}));

const ActionButton = styled(Button)`
  margin-left: 20px;
  text-align: left;
  height: 48px;
  color: var(--btn-text);
  background: var(--primary);
  font-size: 16px;
  font: Kufam;
  font-weight: 600;
  border-radius: 30px;
  padding: 10px 20px;
  &:hover {
    background: var(--hover); /* Change the background color on hover */
  }

  &:active {
    background: var(--primary); /* Change the background color on click */
  }
`

const AddressModal: React.FC<AddressModalProps> = ({
  open,
  setIsAddressModalOpen,
  selectedAddress,
  setSelectedAddress,
  addressList,
  setAddressList,
}) => {
  const DefaultLocation = {
    lat: Number(
      selectedAddress?.latitude ? Number(selectedAddress.latitude) : 13.067439
    ),
    lng: Number(
      selectedAddress?.longitude ? Number(selectedAddress.longitude) : 80.237617
    ),
  };
  const [defaultLocation, setDefaultLocation] = useState(DefaultLocation);
  const [location, setLocation] = useState(defaultLocation);

  const userDetail = useSelector((state: RootState) => state.userDetail);

  const apiEndpoint = process.env.REACT_APP_LOGIN_API_ENDPOINT;
  const accessToken = sessionStorage.getItem("access_token");

  const [isPrimary, setIsPrimary] = useState(
    selectedAddress?.address_type === "PRIMARY" ? true : false
  );

  const [formData, setFormData] = useState<FormData>(
    selectedAddress || {
      title: "",
      address: "",
      city: "",
      state: "",
      country: "",
      pincode: "",
      longitude: "",
      latitude: "",
      address_type: "",
    }
  );
  const [errors, setErrors] = useState<any>({
    title: "",
    address: "",
    city: "",
    state: "",
    country: "",
    pincode: "",
  });

  useEffect(() => {
    if (selectedAddress) {
      setFormData(selectedAddress);
      //   setDefaultLocation({ lat: selectedAddress.latitude, lng: selectedAddress.longitude });
      setLocation({
        lat: Number(selectedAddress.latitude),
        lng: Number(selectedAddress.longitude),
      });
    }
  }, [selectedAddress]);

  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement | { name?: string; value: unknown }>
  ) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name as string]: value as string,
    }));
    setErrors({ ...errors, [name as string]: "" });
  };

  const handleSubmit = async () => {
    if (handleValidation()) {
      try {
        let url: string = `${apiEndpoint}/users/addresses/${selectedAddress.id}`;

        const requestBody: any = {
          title: formData.title,
          address: formData.address,
          city: formData.city,
          state: formData.state,
          country: formData.country,
          pincode: formData.pincode,
          latitude: location?.lat || 0,
          longitude: location?.lng || 0,
          address_type: isPrimary ? "PRIMARY" : "SECONDARY",
        };
        const response = await fetch(url, {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${accessToken}`,
          },
          body: JSON.stringify(requestBody),
        });
        const data = await response.json();
        if (data) {
          setAddressList((prevAddresses: any) => {
            const indexToRemove = prevAddresses.findIndex(
              (item: any) => item.id === selectedAddress.id
            );

            if (indexToRemove !== -1) {
              const updatedItems = [
                ...prevAddresses.slice(0, indexToRemove),
                ...prevAddresses.slice(indexToRemove + 1),
                data.address,
              ];

              return updatedItems;
            }
          });

          setFormData({
            title: "",
            address: "",
            city: "",
            state: "",
            country: "",
            pincode: "",
            longitude: "",
            latitude: "",
            address_type: "",
          });
          toast.success(`Address updated successfully`, {
            position: "top-right",
            autoClose: 5000,
          });

          setIsAddressModalOpen(false);
          setSelectedAddress(data.address);
        } else {
          toast.warning(`Unable to add new address. Please try again`, {
            position: "top-right",
            autoClose: 5000,
          });
        }
      } catch (error) {
        console.error("Error fetching Services:", error);
      }
    }
  };

  const handleValidation = () => {
    const newErrors = {
      title: "",
      address: "",
      city: "",
      state: "",
      country: "",
      pincode: "",
    };

    if (!formData.title) {
      newErrors.title = "Title is required";
    }

    if (!formData.address) {
      newErrors.address = "Street is required";
    }

    if (!formData.city) {
      newErrors.city = "City is required";
    }

    if (!formData.state) {
      newErrors.state = "State is required";
    }
    if (!formData.country) {
      newErrors.country = "Country is required";
    }

    if (!formData.pincode) {
      newErrors.pincode = "Pincode is required";
    }

    setErrors(newErrors);

    return (
      !newErrors.pincode &&
      !newErrors.state &&
      !newErrors.city &&
      !newErrors.address &&
      !newErrors.title
    );
  };

  const updatePrimary = (event: React.ChangeEvent<HTMLInputElement>) => {
    setIsPrimary(event.target.checked);
  };

  useEffect(() => {
    //API call to fetch customer address list
    if (userDetail?.addresses.length > 0) {
      setAddressList(
        userDetail?.addresses && userDetail?.addresses?.length > 0
          ? userDetail.addresses
          : []
      );
    }
  }, []);

  return (
    <Dialog
      open={open}
      onClose={() => {
        setIsAddressModalOpen(false);
      }}
      maxWidth="md"
      fullWidth
    >
      <DialogTitle>
        <Typography variant="h6" sx={{ display: "flex", alignItems: "center" }}>
          Edit Address
          <IconButton
            aria-label="close"
            onClick={() => {
              setIsAddressModalOpen(false);
              setErrors({
                title: "",
                address: "",
                city: "",
                state: "",
                country: "",
                pincode: "",
              });
              setFormData({
                title: "",
                address: "",
                city: "",
                state: "",
                country: "",
                pincode: "",
                longitude: "",
                latitude: "",
                address_type: "",
              });
            }}
            sx={{ position: "absolute", right: 2, top: 8 }}
          >
            <CloseIcon sx={{ fontSize: "1.5rem" }} />
          </IconButton>
        </Typography>
      </DialogTitle>
      {formData && (
        <DialogContent sx={{ p: 0 }}>
          <Box sx={{ p: 3, mb: 1 }}>
            {/* <Typography variant="h6" sx={{ fontSize: '16px' }}>
            Add new address
          </Typography> */}
            <form>
              <Grid container spacing={1}>
                <Grid item xs={6}>
                  <TextField
                    label="Title *"
                    name="title"
                    value={formData.title}
                    onChange={handleChange}
                    fullWidth
                    margin="normal"
                  />
                  <ErrorLabel>{errors.title}</ErrorLabel>
                </Grid>
                {/* <Grid item xs={6} sx={{ display: 'flex', justifyContent: 'center' }}>
                  <FormControlLabel
                    control={<Checkbox size="medium" defaultChecked onChange={updatePrimary} checked={isPrimary} />}
                    label="Default"
                  />
                </Grid> */}
                <Grid item xs={12}>
                  <TextField
                    label="Street *"
                    name="address"
                    value={formData.address}
                    onChange={handleChange}
                    fullWidth
                    margin="normal"
                  />
                  <ErrorLabel>{errors.address}</ErrorLabel>
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    label="City *"
                    name="city"
                    value={formData.city}
                    onChange={handleChange}
                    fullWidth
                    margin="normal"
                  />
                  <ErrorLabel>{errors.city}</ErrorLabel>
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    label="State *"
                    name="state"
                    value={formData.state}
                    onChange={handleChange}
                    fullWidth
                    margin="normal"
                  />
                  <ErrorLabel>{errors.state}</ErrorLabel>
                </Grid>
              </Grid>
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <TextField
                    label="Country *"
                    name="country"
                    value={formData.country}
                    onChange={handleChange}
                    fullWidth
                    margin="normal"
                  />
                  <ErrorLabel>{errors.country}</ErrorLabel>
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    label="Pin Code *"
                    name="pincode"
                    value={formData.pincode}
                    onChange={handleChange}
                    fullWidth
                    margin="normal"
                  />
                  <ErrorLabel>{errors.pincode}</ErrorLabel>
                </Grid>
              </Grid>
            </form>
          </Box>
          {selectedAddress && (
            <Grid container spacing={2} style={{ padding: "0px 20px" }}>
              <Grid item xs={12}>
                Select Location *
              </Grid>
              <Grid item xs={12}>
                <Location
                  address={formData}
                  selectedAddress={selectedAddress}
                  setDefaultLocation={setDefaultLocation}
                  location={location}
                  setLocation={setLocation}
                  defaultLocation={defaultLocation}
                  DefaultLocation={DefaultLocation}
                  flag="edit"
                />
              </Grid>
            </Grid>
          )}
        </DialogContent>
      )}
      <DialogActions sx={{ border: "none", mb: 3, mr: 2 }}>
        <Button
          variant="outlined"
          onClick={() => {
            setIsAddressModalOpen(false);
            setErrors({
              title: "",
              address: "",
              city: "",
              state: "",
              country: "",
              pincode: "",
            });
            setFormData({
              title: "",
              address: "",
              city: "",
              state: "",
              country: "",
              pincode: "",
              longitude: "",
              latitude: "",
              address_type: "",
            });
          }}
          color="primary"
          sx={{ padding: "10px 30px !important" }}
        >
          Cancel
        </Button>

        <ActionButton
          type="button"
          variant="contained"
          onClick={handleSubmit}
          color="primary"
          sx={{ padding: "10px 30px !important" }}
        >
          Save
        </ActionButton>
      </DialogActions>
    </Dialog>
  );
};

export default AddressModal;
