import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Card,
  Chip,
  Divider,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Radio,
  RadioGroup,
  Select,
  SelectChangeEvent,
  TextField,
  Theme,
  Typography,
} from "@mui/material";
import styled from "@emotion/styled";
import { toast } from "react-toastify";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import dayjs from "dayjs";
import axios from "axios";

import { theme } from "../../utils/theme";
import "./index.scss";

const ProfilePic = require("../../assets/profile-pic.svg").default;

const BoxCard = styled(Box)`
  padding: 3rem 10rem;
`;

const LoginButton = styled(Button)`
  text-align: left;
  color: var(--btn-text);
  background: var(--primary);
  font: Kufam;
  font-weight: 600;
  border-radius: 30px;
  padding: 10px 20px;
  &:hover {
    background: var(--hover); /* Change the background color on hover */
  }

  &:active {
    background: var(--primary); /* Change the background color on click */
  }
`

const InputField = styled(TextField)`
  border-radius: 8px;
  margin-top: 8px;
  margin-bottom: 16px;
  & .MuiOutlinedInput-root {
    fieldset {
      border-color: ${theme.palette.primary.contrastText};
    }

    &:hover fieldset {
      border-color: ${theme.palette.primary.contrastText};
    }

    &.Mui-focused fieldset {
      border-color: ${theme.palette.primary.main};
    }

    &.Mui-error fieldset {
      border-color: #your-error-color; /* Error state */
    }
  }
`;

const ErrorLabel = styled(Typography)(() => ({
  color: "red",
  fontSize: "0.875rem", // Adjust the font size as needed
  fontWeight: 500,
  marginBottom: "15px", // Add margin as needed
  textAlign: "left",
}));

const AddNewEmployee = (props: any) => {
  const {
    setEditEmployee,
    setEmployeeList,
    employeeList,
    invidualEmpDetails,
    setInvidualEmpDetails,
  } = props;
  const apiEndpoint = process.env.REACT_APP_LOGIN_API_ENDPOINT;

  const [errors, setErrors] = useState({
    first_name: "",
    last_name: "",
    phone_number: "",
    // phone_number_prefix: '',
    // image: Emp1,
    dob: "",
    gender: "",
    addresses: "",
    experience: "",
    designation: "",
    address: "",
    city: "",
    state: "",
    country: "",
    pincode: "",
  });

  const [profilePicture, setProfilePicture] = useState(ProfilePic);
  const [selectedFile, setSelectedFile] = useState(null);
  const [isProfilePicUpdated, setIsProfilePicUpdated] = useState(false);

  const handleValidation = () => {
    const newErrors = {
      first_name: "",
      last_name: "",
      phone_number: "",
      //   phone_number_prefix: '',
      dob: "",
      gender: "",
      addresses: "",
      experience: "",
      designation: "",
      address: "",
      city: "",
      state: "",
      country: "",
      pincode: "",
    };

    if (!invidualEmpDetails.first_name) {
      newErrors.first_name = "First name is required";
    }

    if (!invidualEmpDetails.last_name) {
      newErrors.last_name = "Last name is required";
    }

    if (!invidualEmpDetails.dob) {
      newErrors.dob = "DOB is required";
    }

    if (!invidualEmpDetails.gender) {
      newErrors.gender = "Gender is required";
    }

    // if (!invidualEmpDetails.experience) {
    //   newErrors.experience = 'Experience is required';
    // }

    if (!invidualEmpDetails.phone_number) {
      newErrors.phone_number = "Mobile number is required";
    }

    // if (!validateMobileNumber(invidualEmpDetails.phone_number)) {
    //   newErrors.phone_number = 'Invalid mobile number';
    // }

    // if (!invidualEmpDetails.phone_number_prefix) {
    //   newErrors.phone_number_prefix = 'Prefix is required';
    // }

    if (!invidualEmpDetails.designation) {
      newErrors.designation = "Designation is required";
    }

    if (invidualEmpDetails.addresses) {
      if (!invidualEmpDetails.addresses[0]?.address) {
        newErrors.address = "Street is required";
      }

      if (!invidualEmpDetails.addresses[0]?.city) {
        newErrors.city = "City is required";
      }

      if (!invidualEmpDetails.addresses[0]?.state) {
        newErrors.state = "State is required";
      }

      if (!invidualEmpDetails.addresses[0]?.country) {
        newErrors.country = "Country is required";
      }

      if (!invidualEmpDetails.addresses[0]?.pincode) {
        newErrors.pincode = "Pincode is required";
      }
    } else {
      newErrors.address = "Street is required";
      newErrors.city = "City is required";
      newErrors.state = "State is required";
      newErrors.country = "Country is required";
      newErrors.pincode = "Pincode is required";
    }

    setErrors(newErrors);

    return (
      !newErrors.first_name &&
      !newErrors.last_name &&
      !newErrors.dob &&
      !newErrors.gender &&
      !newErrors.experience &&
      !newErrors.first_name &&
      !newErrors.phone_number &&
      !newErrors.designation &&
      !newErrors.address &&
      !newErrors.city &&
      !newErrors.state &&
      !newErrors.country &&
      !newErrors.pincode
    );
  };

  const validateMobileNumber = (mobileNumber: string): boolean => {
    const mobileNumberRegex = /^[0-9]{10}$/;
    return mobileNumberRegex.test(mobileNumber);
  };

  const formatDobDate = (inputDate: string) => {
    const date = new Date(inputDate);

    // Get day, month, and year components
    const day = date.getDate().toString().padStart(2, "0");
    const month = (date.getMonth() + 1).toString().padStart(2, "0"); // Months are zero-based
    const year = date.getFullYear();

    // Format the date as "yyyy-mm-dd"
    const formattedDate = `${year}-${month}-${day}`;
    return formattedDate;
  };

  useEffect(() => {
    console.timeLog(invidualEmpDetails, "_____updated invidualEmpDetails");
  }, [invidualEmpDetails]);

  const handleChange = (prop: any) => (event: any) => {
    setInvidualEmpDetails({
      ...invidualEmpDetails,
      [prop]: event.target.value,
    });
    setErrors({ ...errors, [prop]: "" });
  };

  const handleAddressChange = (prop: any) => (event: any) => {
    setInvidualEmpDetails((prevDetails: any) => {
      const sample = {
        ...prevDetails,
        addresses: [
          {
            ...prevDetails.addresses[0], // If you only have one address in the array
            [prop]: event.target.value,
          },
        ],
      };
      return sample;
    });

    setErrors({ ...errors, [prop]: "" });
  };

  function generateRandomEmail() {
    const username = Math.random().toString(36).substring(7);
    const domain = ["gmail.com", "yahoo.com", "outlook.com", "example.com"]; // Add more domains as needed
    const randomDomain = domain[Math.floor(Math.random() * domain.length)];

    return `${username}@${randomDomain}`;
  }

  const saveEmployeeData = async (e: any) => {
    const accessToken = sessionStorage.getItem("access_token");
    const formData = new FormData();
    if (selectedFile) formData.append("image", selectedFile);

    if (handleValidation()) {
      e.preventDefault();

      if (isProfilePicUpdated) {
        const profileURL = `${apiEndpoint}/vendors/employees/${invidualEmpDetails.emp_code}/update_employee_profile_picture/`;

        try {
          const response = await axios.put(profileURL, formData, {
            headers: {
              "Content-Type": "multipart/form-data",
              Authorization: `Bearer ${accessToken}`,
            },
          });
        } catch (error) {
          console.error("Error uploading image:", error);
        }
      }

      const requestBody = {
        first_name: invidualEmpDetails.first_name,
        last_name: invidualEmpDetails.last_name,
        // phone_number: `${invidualEmpDetails.phone_number_prefix} ${invidualEmpDetails.phone_number}`,
        phone_number: invidualEmpDetails.phone_number,
        dob: invidualEmpDetails.dob,
        gender: invidualEmpDetails.gender,
        status: invidualEmpDetails.status,
        designation: invidualEmpDetails.designation,
        email: generateRandomEmail(),
        address: invidualEmpDetails.addresses[0].address,
        city: invidualEmpDetails.addresses[0].city,
        state: invidualEmpDetails.addresses[0].state,
        country: invidualEmpDetails.addresses[0].country,
        pincode: invidualEmpDetails.addresses[0].pincode,
      };

      try {
        const response = await fetch(
          `${apiEndpoint}/vendors/employees/${invidualEmpDetails.emp_code}`,
          {
            method: "PUT",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${accessToken}`,
            },
            body: JSON.stringify(requestBody),
          }
        );
        const data = await response.json();
        if (response.ok && data && data.employee) {
          setEditEmployee(false);

          const updatedList = employeeList.filter(
            (employee: any) => employee.emp_code !== invidualEmpDetails.emp_code
          );

          setEmployeeList([...updatedList, data.employee]);
          setInvidualEmpDetails(data.employee);
          toast.success("Employee Updated", {
            position: "top-right",
            autoClose: 5000,
          });
        } else {
          toast.warning("Unable to update employee", {
            position: "top-right",
            autoClose: 5000,
          });
        }
      } catch (error) {
        console.error("Error during saving employee:", error);
        toast.error("Someting went wrong", {
          position: "top-right",
          autoClose: 5000,
        });
      }
    }
  };

  const handleFileChange = (e: any) => {
    const file = e.target.files[0];

    if (file) {
      setSelectedFile(file);

      const reader = new FileReader();
      reader.onloadend = () => {
        const dataUrl = reader.result;
        setProfilePicture(dataUrl);
        setInvidualEmpDetails({
          ...invidualEmpDetails,
          ["profile_picture_url"]: dataUrl,
        });
        setIsProfilePicUpdated(true);
      };

      reader.readAsDataURL(file);
    }
  };

  return (
    <Grid container sx={{ mt: 3 }}>
      <Card sx={{ width: "100%" }}>
        <BoxCard>
          <Typography
            component="div"
            sx={{
              fontSize: "16px",
              color: "#000000",
              fontWeight: 600,
              lineHeight: "20px",
            }}
          >
            Basic details
          </Typography>

          <Box
            className="dynamic-form"
            sx={{ display: "flex", justifyContent: "space-between", my: 2 }}
          >
            <div
              className={
                !isProfilePicUpdated && !invidualEmpDetails?.profile_picture_url
                  ? "Rectangle-5625"
                  : "Rectangle-5627"
              }
            >
              <img
                src={
                  invidualEmpDetails?.profile_picture_url
                    ? invidualEmpDetails.profile_picture_url
                    : profilePicture
                }
                alt="Profile Picture"
                style={
                  !isProfilePicUpdated &&
                  !invidualEmpDetails?.profile_picture_url
                    ? {
                        height: "96px",
                        width: "96px",
                      }
                    : {
                        height: "200px",
                        maxWidth: "180px",
                        borderRadius: "8px",
                        // padding: '10px 0px',
                        border: "1px solid var(--secondary)",
                      }
                }
              />
              <input
                type="file"
                id="file-input"
                onChange={handleFileChange}
                accept=".png, .jpg, .jpeg"
                required
              />
              <label
                htmlFor="file-input"
                className="Drag-and-drop-or-Choose-file-to-upload label-style"
                style={{ cursor: "pointer" }}
              >
                <Typography
                  variant="body1"
                  component="span"
                  px={1}
                  color="primary"
                >
                  {!isProfilePicUpdated ? "Upload Picture" : "Change Picture"}
                </Typography>
              </label>
            </div>

            <Grid container sx={{ ml: 3 }}>
              <Grid item xs={12} md={6} sx={{ padding: "0 10px" }}>
                <InputLabel htmlFor="first-name"> First Name *</InputLabel>
                <InputField
                  id="first-name"
                  fullWidth
                  margin="normal"
                  value={invidualEmpDetails.first_name}
                  onChange={handleChange("first_name")}
                />
                <ErrorLabel>{errors.first_name}</ErrorLabel>
              </Grid>
              <Grid item xs={12} md={6} sx={{ padding: "0 10px" }}>
                <InputLabel htmlFor="last-name"> Last name *</InputLabel>
                <InputField
                  id="last-name"
                  type="text"
                  fullWidth
                  margin="normal"
                  value={invidualEmpDetails.last_name}
                  onChange={handleChange("last_name")}
                />
                <ErrorLabel>{errors.last_name}</ErrorLabel>
              </Grid>
              <Grid item xs={12} md={6} sx={{ padding: "0 10px" }}>
                <InputLabel htmlFor="dob"> DOB *</InputLabel>

                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker
                    sx={{ width: "100%", mt: 1, mb: 2 }}
                    format="DD-MM-YYYY"
                    views={["year", "month", "day"]}
                    defaultValue={dayjs(invidualEmpDetails.dob)}
                    value={dayjs(invidualEmpDetails.dob)}
                    onChange={(date) => {
                      if (date) {
                        setInvidualEmpDetails({
                          ...invidualEmpDetails,
                          ["dob"]: dayjs(date),
                        });
                        setErrors({ ...errors, ["dob"]: "" });
                      } else {
                        setInvidualEmpDetails({
                          ...invidualEmpDetails,
                          ["dob"]: "",
                        });
                        setErrors({ ...errors, ["dob"]: "DOB is required" });
                      }
                    }}
                    disableFuture
                  />
                </LocalizationProvider>
                <ErrorLabel>{errors.dob}</ErrorLabel>
              </Grid>
              <Grid item xs={12} md={6} sx={{ padding: "0 10px" }}>
                <InputLabel htmlFor="gender"> Gender *</InputLabel>
                <Select
                  value={invidualEmpDetails.gender}
                  onChange={handleChange("gender")}
                  // onChange={(e) => handleQuestionChange(questionIndex, 'type', e.target.value)}
                  required
                  sx={{ width: "100%", mt: 1, mb: 2 }}
                >
                  <MenuItem value="MALE">Male</MenuItem>
                  <MenuItem value="FEMALE">Female</MenuItem>
                </Select>
                <ErrorLabel>{errors.gender}</ErrorLabel>
              </Grid>
              {/* <Grid item xs={12} md={3} sx={{ padding: '0 10px' }}>
                <InputLabel htmlFor="experience"> Experience</InputLabel>
                <InputField
                  id="experience"
                  type="text"
                  fullWidth
                  margin="normal"
                  value={invidualEmpDetails.experience}
                  onChange={handleChange('experience')}
                />
                <ErrorLabel>{errors.experience}</ErrorLabel>
              </Grid> */}
            </Grid>
          </Box>
        </BoxCard>
        <Divider flexItem />
        <BoxCard>
          <Typography
            component="div"
            sx={{
              fontSize: "16px",
              color: "#000000",
              fontWeight: 600,
              lineHeight: "20px",
            }}
          >
            Personal details
          </Typography>
          <Grid container sx={{ mt: 2 }}>
            <Grid item xs={12} md={6} sx={{ padding: "0 10px 0px 0px" }}>
              <InputLabel htmlFor="mobile-no"> Mobile No *</InputLabel>
              <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                {/* <InputField
                  id="mobile-no"
                  fullWidth
                  margin="normal"
                  sx={{ width: '100px' }}
                  placeholder="+91"
                  value={invidualEmpDetails.phone_number_prefix}
                  onChange={handleChange('phone_number_prefix')}
                /> */}

                <InputField
                  id="mobile-no"
                  fullWidth
                  margin="normal"
                  sx={{
                    width: "100%",
                    //    ml: 2
                  }}
                  value={invidualEmpDetails.phone_number}
                  onChange={handleChange("phone_number")}
                />
              </Box>
              <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                {/* <ErrorLabel>{errors.phone_number_prefix}</ErrorLabel> */}
                <ErrorLabel>{errors.phone_number}</ErrorLabel>
              </Box>
            </Grid>
            <Grid item xs={12} md={12} sx={{ padding: "0 10px 0px 0px" }}>
              <InputLabel htmlFor="address"> Address *</InputLabel>
              <Box sx={{ display: "flex" }}>
                <InputField
                  id="Street"
                  type="text"
                  fullWidth
                  margin="normal"
                  sx={{ width: "40%" }}
                  placeholder="Street *"
                  value={invidualEmpDetails.addresses[0]?.address}
                  onChange={handleAddressChange("address")}
                />
                <InputField
                  id="city"
                  placeholder="City *"
                  type="text"
                  fullWidth
                  margin="normal"
                  sx={{ width: "30%", ml: 2 }}
                  value={invidualEmpDetails.addresses[0]?.city}
                  onChange={handleAddressChange("city")}
                />
                <InputField
                  id="State"
                  placeholder="State *"
                  type="text"
                  fullWidth
                  margin="normal"
                  sx={{ width: "25%", ml: 2 }}
                  value={invidualEmpDetails.addresses[0]?.state}
                  onChange={handleAddressChange("state")}
                />
              </Box>
              <Box sx={{ display: "flex" }}>
                <ErrorLabel sx={{ width: "40%" }}>{errors.address}</ErrorLabel>
                <ErrorLabel sx={{ width: "30%", ml: 2 }}>
                  {errors.city}
                </ErrorLabel>
                <ErrorLabel sx={{ width: "25%", ml: 2 }}>
                  {errors.state}
                </ErrorLabel>
              </Box>
            </Grid>
            <Grid item xs={12} md={12} sx={{ padding: "0 10px 0px 0px" }}>
              <Box sx={{ display: "flex" }}>
                <InputField
                  id="country"
                  placeholder="Country *"
                  type="text"
                  fullWidth
                  margin="normal"
                  sx={{ width: "20%" }}
                  value={invidualEmpDetails.addresses[0]?.country}
                  onChange={handleAddressChange("country")}
                />
                <InputField
                  id="pincode"
                  placeholder="Pincode *"
                  type="text"
                  fullWidth
                  margin="normal"
                  sx={{ width: "20%", ml: 2 }}
                  value={invidualEmpDetails.addresses[0]?.pincode}
                  onChange={handleAddressChange("pincode")}
                />
              </Box>
              <Box sx={{ display: "flex" }}>
                <ErrorLabel sx={{ width: "20%" }}>{errors.country}</ErrorLabel>
                <ErrorLabel sx={{ width: "20%", ml: 2 }}>
                  {errors.pincode}
                </ErrorLabel>
              </Box>
            </Grid>
          </Grid>
        </BoxCard>
        <Divider flexItem />
        <BoxCard>
          <Typography
            component="div"
            sx={{
              fontSize: "16px",
              color: "#000000",
              fontWeight: 600,
              lineHeight: "20px",
            }}
          >
            Skillset *
          </Typography>
          <Grid container sx={{ mt: 2 }}>
            <Grid item xs={12} md={12} sx={{ padding: "0 10px 0px 0px" }}>
              <FormControl>
                <FormLabel id="type-of-employee">Type of employee</FormLabel>
                <RadioGroup
                  aria-labelledby="type-of-employee"
                  name="radio-buttons-group"
                  row
                  onChange={handleChange("designation")}
                  defaultValue={invidualEmpDetails.designation}
                >
                  <FormControlLabel
                    sx={{
                      backgroundColor: "#fff",
                      border: "1px solid #C1D0E0",
                      borderRadius: "4px",
                      paddingRight: "15px",
                      ml: "4px",
                      mt: 2,
                    }}
                    value="ESTIMATOR"
                    control={<Radio />}
                    label="Estimator"
                  />
                  <FormControlLabel
                    sx={{
                      backgroundColor: "#fff",
                      border: "1px solid #C1D0E0",
                      borderRadius: "4px",
                      paddingRight: "15px",
                      ml: "4px",
                      mt: 2,
                    }}
                    value="HANDYMAN"
                    control={<Radio />}
                    label="Handyman"
                  />
                  <FormControlLabel
                    sx={{
                      backgroundColor: "#fff",
                      border: "1px solid #C1D0E0",
                      borderRadius: "4px",
                      paddingRight: "15px",
                      ml: "4px",
                      mt: 2,
                    }}
                    value="BOTH"
                    control={<Radio />}
                    label="Both"
                  />
                </RadioGroup>
              </FormControl>

              <ErrorLabel sx={{ mt: 2 }}>{errors.designation}</ErrorLabel>
            </Grid>
          </Grid>
        </BoxCard>
        <Divider flexItem />
        <Box sx={{ textAlign: "right", py: 5, mr: 2 }}>
          <Button
            sx={{
              borderRadius: "30px",
              mr: 2,
              padding: "10px 20px",
              color: "#1F261F",
            }}
            onClick={() => {
              setEditEmployee(false);
              setInvidualEmpDetails(null);
              setErrors({
                first_name: "",
                last_name: "",
                phone_number: "",
                // phone_number_prefix: '',
                // image: Emp1,
                dob: "",
                gender: "",
                addresses: "",
                experience: "",
                designation: "",
                address: "",
                city: "",
                state: "",
                country: "",
                pincode: "",
              });
            }}
          >
            Cancel
          </Button>

          <LoginButton
            variant="contained"
            sx={{ borderRadius: "30px" }}
            onClick={saveEmployeeData}
          >
            Save Changes
          </LoginButton>
        </Box>
      </Card>
    </Grid>
  );
};

export default AddNewEmployee;
