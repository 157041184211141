import { useEffect, useState } from "react"
import {
  Typography,
  Box,
  Grid,
  Paper,
  Card,
  CardMedia,
  CardContent,
  useMediaQuery,
  IconButton,
  Tooltip,
} from "@mui/material"
import { useDispatch } from "react-redux"
import { useNavigate } from "react-router-dom"
import FmdGoodOutlinedIcon from "@mui/icons-material/FmdGoodOutlined"
import { setActiveSelectedCategory, setSearchService } from "../../store"
import {
  Address,
  device,
  email,
  phoneNumber1,
  phoneNumber2,
} from "../../utils/constant"
import { theme } from "../../utils/theme"
import {
  Container,
  StyledCard,
  CardContentWrapper,
  LogoContainer,
  LogoText,
  HeaderText,
  GradientRectangle,
  ViewDetailsLink,
  FooterText,
  MascotSubTitle,
  MascotBox,
  InspectionButton,
  CustomerStasificationTitle,
  CustomerStasificationSubTitle,
  CustomerStasificationSubscriptTitle,
  FooterTitle,
} from "./home-styles"
import Header from "../../components/core/header"
import ExpandMoreIcon from "@mui/icons-material/ExpandMore"
import ExpandLessIcon from "@mui/icons-material/ExpandLess"
import ArrowLeftIcon from "@mui/icons-material/ArrowLeft"
import ArrowRightIcon from "@mui/icons-material/ArrowRight"
import AOS from "aos"
import "aos/dist/aos.css"
import StarRating from "./StarRating"

export const FacebookIcon =
  require("../../assets/icons/facebook_icon.svg").default
export const InstagramIcon =
  require("../../assets/icons/instagram_icon.svg").default

// export const Logo = require("../../assets/Logo-hello-fixy-green.svg").default
export const Logo = require("../../assets/Logo-hello-fixy-blue2.svg").default
export const homeCover = require("../../assets/homeCover.svg").default
// export const Mascot = require("../../assets/mascot1.svg").default
export const Mascot = require("../../assets/mascot2.svg").default
const EmpNo = require("../../assets/profiles/emp-no.svg").default
const StarIcon = require("../../assets/icons/star.svg").default
// const artMasco = require("../../assets/mascoArt.svg").default
const artMasco = require("../../assets/mascoArt-1.svg").default
// const customerStasificationBadge =
//   require("../../assets/customer-stasification-badge.svg").default
const customerStasificationBadge =
  require("../../assets/customer-stasification-badge-1.svg").default
// const quotationHome = require("../../assets/quotation-home.svg").default
const quotationHome = require("../../assets/quotation-home-1.svg").default
// const scheduleHome = require("../../assets/schedule-home.svg").default
const scheduleHome = require("../../assets/schedule-home-1.svg").default
// const workDoneHome = require("../../assets/workDone-home.svg").default
const workDoneHome = require("../../assets/workDone-home-1.svg").default

interface Category {
  id: string
  name: string
  short_description: string
  description: string
  image_url: string
}

interface Service {
  id: string
  name: string
  short_description: string
  description: string
  image_url: string
  price: string
}

const portfolioList = [
  {
    id: 1,
    image: EmpNo,
    content: "Excellent service and Quality work.",
    rating: "4",
    service: "Painting",
    date: "01-12-2023",
  },
  {
    id: 2,
    image: EmpNo,
    content: "Very professional job installing lights in my house.",
    rating: "4.5",
    service: "Electrical work",
    date: "11-12-2023",
  },
  {
    id: 3,
    image: EmpNo,
    content: "Beautiful interior work on budget.",
    rating: "5",
    service: "Interior",
    date: "09-11-2023",
  },
  {
    id: 4,
    image: EmpNo,
    content: "Thanks for great job my garden looks beautiful now.",
    rating: "4.1",
    service: "Gardening",
    date: "04-12-2023",
  },
  {
    id: 5,
    image: EmpNo,
    content:
      "Thanks for safely moving my furnitures from Chennai to Pondicherry.",
    rating: "3.9",
    service: "Packing and moving",
    date: "12-12-2023",
  },
  {
    id: 6,
    image: EmpNo,
    content: "Thanks for great job and for getting it done so quickly.",
    rating: "4",
    service: "AC installation",
    date: "26-12-2023",
  },
]

const categoryInitialValues = {
  id: "",
  description: "",
  image_url: "",
  name: "",
  short_description: "",
}

export default function NewHome() {
  const navigate = useNavigate()

  const mobileS = useMediaQuery(theme.breakpoints.up("xs"))
  const mobile = useMediaQuery(theme.breakpoints.up("sm"))
  const tabelt = useMediaQuery(theme.breakpoints.up("md"))
  const laptop = useMediaQuery(theme.breakpoints.up("lg"))
  const desktop = useMediaQuery(theme.breakpoints.up("xl"))
  const laptopScreen = useMediaQuery('(min-width: 1024px)')
  const largeLaptopScreen = useMediaQuery('(min-width: 1439px)')

  const iconStyle = desktop
    ? "24px"
    : laptop
    ? "20px"
    : tabelt
    ? "18px"
    : "20px"

  const mascotImage = desktop
    ? "133px"
    : laptop
    ? "100px"
    : tabelt
    ? "100px"
    : mobile
    ? "100px"
    : mobileS
    ? "100px"
    : "120px"

  const allScreens = mobileS || mobile || tabelt || laptop || desktop

  const uptoLaptopScreen = desktop || laptop

  const uptoTabeltScreen = uptoLaptopScreen || tabelt

  const [services, setServices] = useState<Service[]>([])
  const dispatch = useDispatch()
  const apiEndpoint = process.env.REACT_APP_LOGIN_API_ENDPOINT
  const [categories, setCategories] = useState<Category[]>([])
  const [isExpanded, setIsExpanded] = useState<boolean>(false)
  const [_isExpanded, _setIsExpanded] = useState<boolean>(false)
  const [currentIndex, setCurrentIndex] = useState(0)
  const [canGoLeft, setCanGoLeft] = useState(false)
  const [canGoRight, setCanGoRight] = useState(true)

  useEffect(() => {
    setCanGoLeft(currentIndex > 0)
    setCanGoRight(currentIndex < portfolioList.length - 2)
  }, [currentIndex, portfolioList.length])

  const handlePrev = () => {
    if (canGoLeft) {
      setCurrentIndex((prev) => prev - 1)
    }
  }

  const handleNext = () => {
    if (canGoRight) {
      setCurrentIndex((prev) => prev + 1)
    }
  }

  function toggleFooter(event: any): void {
    if (!uptoTabeltScreen) {
      setIsExpanded(!isExpanded)
    }
  }
  function toggleOtherFooter(event: any): void {
    if (!uptoTabeltScreen) {
      _setIsExpanded(!_isExpanded)
    }
  }

  const fetchCategories = async () => {
    try {
      const response = await fetch(`${apiEndpoint}/categories`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      })
      const data = await response.json()
      if (data && data.results) {
        const categoryData: Category[] = data.results
        setCategories(categoryData)
      } else {
        throw new Error("No categories found")
      }
    } catch (error) {
      console.error("Error fetching Categories:", error)
    }
  }
  const fetchServices = async () => {
    try {
      const url = `${apiEndpoint}/services`

      const response = await fetch(url, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      })
      const data = await response.json()

      if (data && data.results) {
        const serviceData: Service[] = data.results
        const randomElements = pickRandomElements(serviceData, uptoTabeltScreen ? 9 : 8)
        setServices(serviceData)
        console.log(services)
      } else {
        throw new Error("No services found")
      }
    } catch (error) {
      console.error("Error fetching Services:", error)
    }
  }
  function shuffleArray(array: Service[]) {
    for (let i = array.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1))
      ;[array[i], array[j]] = [array[j], array[i]]
    }
  }

  function pickRandomElements(array: Service[], count: number) {
    const shuffledArray = [...array]
    // shuffleArray(shuffledArray) // Removed the randomise services list.
    const randomElements = shuffledArray.slice(0, count)
    return randomElements
  }

  useEffect(() => {
    AOS.init({
      duration: 1000,
      offset: 100,
    })
  })

  useEffect(() => {
    // sessionStorage.clear();
    fetchServices()
    fetchCategories()
    dispatch(setSearchService(""))
    dispatch(setActiveSelectedCategory(categoryInitialValues))
    if (uptoTabeltScreen) {
      setIsExpanded(true)
      _setIsExpanded(true)
    }
  }, [])

  const drawerContents = () => {
    return (
      <Box sx={{ padding: "10px" }}>
        <LogoContainer sx={{ justifyContent: "center", alignItems: "center" }}>
          <img src={Logo} alt='Company Logo' style={{ height: 40 }} />
          <LogoText>HelloFixy</LogoText>
        </LogoContainer>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            marginTop: 2,
            rowGap: 3,
          }}>
          <HeaderText onClick={() => navigate("/all-services")}>
            Services
          </HeaderText>
          <HeaderText onClick={() => navigate("/help-center")}>FAQs</HeaderText>

          <HeaderText onClick={() => navigate("/about")}>
            <div style={{ display: "flex", columnGap: "4px" }}>
              <span>About</span>
              <span>us</span>
            </div>
          </HeaderText>
        </Box>
        <Box
          sx={{
            display: "flex",
            padding: "20px 7px",
            justifyContent: "center",
            alignItems: "center",
          }}>
          <FmdGoodOutlinedIcon
            style={{
              fontSize: iconStyle,
            }}
          />
          <LogoText>Chennai</LogoText>
        </Box>
      </Box>
    )
  }

  return (
    <Container sx={{ width: "100vw", m: 0, p: 0 }}>
      <Box sx={{ px: 3, mb: 0, width: "100%" }}>
        <Header />
      </Box>
      <Grid
        container
        direction='column'
        justifyContent='space-evenly'
        alignItems='center'>
        <GradientRectangle
          style={{
            height: uptoTabeltScreen ? "395px" : "260px",
          }}>
          <img
            src={homeCover}
            alt='CoverImage'
            style={{
              width: "100%",
              height: uptoTabeltScreen ? "395px" : "260px",
              objectFit: "cover",
              position: "absolute",
            }}
          />
        </GradientRectangle>

        <Box
          sx={{
            display: "flex",
            flexDirection: uptoTabeltScreen ? "row" : "column",
            mt: uptoTabeltScreen ? 5 : 2,
            px: uptoTabeltScreen ? 10 : mobileS || mobile ? 4 : 2,
            alignItems: uptoTabeltScreen ? "" : "center",
          }}>
          <img
            src={Mascot}
            alt='Mascot'
            style={{
              backgroundColor: "var(--bg) !important",
              width: mascotImage,
              height: mascotImage,
              marginTop: 5,
            }}
          />
          <MascotBox>
            <Typography
              sx={{
                fontSize: desktop
                  ? "56px"
                  : laptop
                  ? "40px"
                  : tabelt
                  ? "30px"
                  : mobile
                  ? "21px"
                  : mobileS
                  ? "21px"
                  : "20px",
                fontWeight: 700,
                lineHeight: allScreens ? "2rem" : "1.5rem",
                textAlign: uptoTabeltScreen ? "start" : "center",
              }}>
              Trusted services, reliable experts
            </Typography>
            <MascotSubTitle>
              We have different range of services that caters to all types of
              contract, remodelling and repair needs.
            </MascotSubTitle>
          </MascotBox>
        </Box>
        <Box
          sx={{
            mt: 3,
            justifyContent: "space-evenly",
            display: "flex",
            px: uptoTabeltScreen ? 4 : 1,
          }}>
          {categories &&
            categories.length > 0 &&
            categories.map((icon) => (
              <Grid
                item
                key={icon.id}
                xs={12}
                sm={2}
                style={{ paddingLeft: 0, paddingRight: 0 }}>
                <Paper
                  elevation={3}
                  sx={{
                    textAlign: "center",
                    padding: "0px",
                    boxShadow: "none",
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    dispatch(setActiveSelectedCategory(icon))
                    navigate("/all-services")
                  }}>
                  <img
                    src={icon.image_url}
                    alt={`Icon ${icon.id}`}
                    style={{
                      width: uptoTabeltScreen ? "35px" : "30px",
                      height: uptoTabeltScreen ? "35px" : "30px",
                    }}
                  />
                  <Typography
                    sx={{ fontSize: uptoTabeltScreen ? "10px" : "9px" }}>
                    {icon.name}
                  </Typography>
                </Paper>
              </Grid>
            ))}
        </Box>
        <InspectionButton
          variant='contained'
          color='primary'
          sx={{ mt: 4 }}
          onClick={() => navigate("/all-services")}>
          Book an Inspection
        </InspectionButton>
        <Grid
          container
          sx={{
            marginTop: uptoLaptopScreen ? "4rem" : "2rem",
            paddingLeft: uptoLaptopScreen ? "10rem" : "2rem",
            paddingRight: uptoLaptopScreen ? "10rem" : "2rem",
          }}>
          <Grid item xs={12} md={6} sx={{ textAlign: "left" }}>
            <Typography
              sx={{
                color: "#36423C",
                fontSize: desktop
                  ? "30px"
                  : laptop
                  ? "30px"
                  : tabelt
                  ? "24px"
                  : mobile
                  ? "20px"
                  : mobileS
                  ? "20px"
                  : "16px",
                fontWeight: 700,
                lineHeight: "2rem",
              }}>
              Our popular works in your city
            </Typography>
          </Grid>

          <Grid item xs={12} md={6} sx={{ textAlign: "right", mt: 1 }}>
            <ViewDetailsLink
              sx={{ color: "var(--secondary)" }}
              onClick={() => navigate("/all-services")}>
              View all services
            </ViewDetailsLink>
          </Grid>

          <Grid container spacing={uptoTabeltScreen ? 4 : 2} mt={0.01}>
            {services &&
              services.length > 0 &&
              pickRandomElements(services, laptopScreen && !largeLaptopScreen ? 9 : 8).map((service) => (
                <Grid item xs={6} md={6} lg={4} xl={3} key={service.id}>
                  <Card
                    data-aos='fade-right'
                    sx={{
                      width: "100%",
                      mt: uptoTabeltScreen ? 5 : 0,
                      cursor: "pointer",
                      height: uptoTabeltScreen ? "280px" : "220px",
                      boxShadow:
                        "2px 4px 8px 2px rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)",
                      transition: "transform 0.3s ease, box-shadow 0.3s ease",
                      backgroundImage: `linear-gradient(to bottom, rgba(0, 0, 0, 0), rgb(0 16 62 / 71%)), url(${service.image_url})`,
                      backgroundSize: "cover",
                      backgroundPosition: "center",
                      borderRadius: "23px",
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "flex-end",
                      "&:hover": {
                        transform: "scale(1.05)",
                        boxShadow:
                          "2px 8px 16px 4px rgba(0, 0, 0, 0.3), 0 10px 30px 0 rgba(0, 0, 0, 0.25)",
                      },
                    }}
                    onClick={() => {
                      navigate("/all-services")
                      sessionStorage.setItem("service", JSON.stringify(service))
                      dispatch(setActiveSelectedCategory(service))
                    }}>
                    <CardContent
                      sx={{
                        height: { xs: "65px", ms: "85px" },
                        p: uptoTabeltScreen ? "16px 12px" : "12px 12px 0 12px",
                        paddingBottom: 0,
                        display: "flex",
                        justifyContent: "center",
                        alignContent: "center",
                      }}>
                      <Typography
                        gutterBottom
                        variant='h5'
                        component='div'
                        sx={{
                          fontSize: uptoTabeltScreen ? "17px" : "13px",
                          color: "#fff",
                          fontWeight: 600,
                          wordWrap: "break-word",
                          textTransform: "capitalize",
                        }}>
                        {service.name.replace("/", " / ")}
                      </Typography>
                      {/* <Typography
                        variant="body2"
                        sx={{
                          color: "#fff",
                          fontSize: uptoTabeltScreen ? "10px" : "7px",
                          lineHeight: uptoTabeltScreen ? "15px" : "13px",
                        }}
                      >
                        {service?.price && `Price: ${service?.price}`}
                      </Typography> */}
                    </CardContent>
                  </Card>
                </Grid>
              ))}
          </Grid>
        </Grid>
      </Grid>
      <Grid
        container
        sx={{
          marginTop: !uptoTabeltScreen ? "2.5rem" : "5rem",
          paddingLeft: uptoLaptopScreen ? "10rem" : "2rem",
          paddingRight: uptoLaptopScreen ? "10rem" : "2rem",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}>
        <Typography
          sx={{
            color: "#36423C",
            fontSize: desktop
              ? "30px"
              : laptop
              ? "30px"
              : tabelt
              ? "24px"
              : mobile
              ? "20px"
              : mobileS
              ? "18px"
              : "20px",
            fontWeight: 700,
            lineHeight: "2rem",
            textAlign: "center",
            justifyContent: "center",
          }}>
          Getting quality work done is now easy
        </Typography>
        <Box
          data-aos='fade-right'
          sx={{
            // filter: "grayscale(1) brightness(0.95)",
            display: "flex",
            flexWrap: "wrap",
            width: "100%",
            columnGap: uptoTabeltScreen ? "50px" : "20px",
            rowGap: uptoTabeltScreen ? "50px" : "30px",
            marginTop: laptop || desktop ? 10 : 5,
            marginBottom: !uptoLaptopScreen ? 3 : 5,
            alignItems: "center",
            justifyContent: "center",
          }}>
          {!uptoTabeltScreen ? (
            <>
              <img
                src={scheduleHome}
                alt='Company Logo'
                style={{ minWidth: "140px", height: "140px" }}
              />
              <img
                src={quotationHome}
                alt='Company Logo'
                style={{ width: "160px", height: "160px" }}
              />
              <img
                src={workDoneHome}
                alt='Company Logo'
                style={{ width: "160px", height: "160px" }}
              />
            </>
          ) : (
            <>
              <img src={scheduleHome} alt='Company Logo' />
              <img src={quotationHome} alt='Company Logo' />
              <img src={workDoneHome} alt='Company Logo' />
            </>
          )}
        </Box>

        <InspectionButton
          variant='contained'
          color='primary'
          sx={{ width: "290px", mt: 4 }}
          onClick={() => navigate("/all-services")}>
          Book an Inspection
        </InspectionButton>
      </Grid>
      <Grid
        container
        sx={{
          background: "var(--background)",
          paddingLeft: uptoLaptopScreen ? "10rem" : "1rem",
          paddingRight: uptoLaptopScreen ? "10rem" : "1rem",
          paddingBottom: uptoTabeltScreen ? 6 : 4,
          mt: !uptoTabeltScreen ? 4 : 8,
        }}>
        <Grid item sx={{ textAlign: "left" }}>
          <Typography
            sx={{
              color: "#36423C",
              fontSize: uptoTabeltScreen ? "2rem" : "25px",
              fontWeight: 700,
              lineHeight: "2rem",
              marginTop: uptoTabeltScreen ? 4 : 2,
              marginBottom: 4,
            }}>
            What do our customers say
          </Typography>
        </Grid>
        {uptoTabeltScreen ? (
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexWrap: "wrap",
              rowGap: "0.5rem",
              columnGap: "0.5rem",
              padding: "0",
            }}>
            {portfolioList.map((card, index) => (
              <Box
                key={index}
                data-aos='fade-right'
                sx={{
                  background: "#ffffff",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  flexWrap: "wrap",
                  borderRadius: "15px",
                }}>
                <Card
                  sx={{
                    borderRadius: "16px",
                    border: "1px solid var(--secondary-bg)",
                    width: { xs: "100%", sm: "100%", md: "320px" },
                    maxWidth: "320px",
                    background: "#f5f5f5", //FBFFFB
                    alignItems: "center",
                    justifyContent: "center",
                  }}>
                  <StyledCard sx={{ alignItems: "center" }}>
                    <CardContentWrapper>
                      <Typography
                        sx={{
                          color: "#36423C",
                          fontSize: "18px",
                          fontWeight: 700,
                          lineHeight: "30px",
                          display: "flex",
                          alignItems: "center",
                        }}>
                        <StarRating rating={Number(card.rating)} />
                      </Typography>
                      <Typography
                        variant='body2'
                        sx={{
                          my: 1,
                          fontStyle: "italic",
                          color: "#36423C",
                          fontSize: "14px",
                          fontWeight: 600,
                          lineHeight: "30px",
                        }}>
                        {card.content}
                      </Typography>
                    </CardContentWrapper>
                  </StyledCard>
                  <Typography
                    sx={{
                      color: "#CBCBCB",
                      fontSize: "12px",
                      fontWeight: 400,
                      textTransform: "capitalize",
                      textAlign: "right",
                      padding: "0px 10px",
                      lineHeight: "56px",
                    }}>
                    {card.service} | {card.date}
                  </Typography>
                </Card>
              </Box>
            ))}
          </Box>
        ) : (
          <Box
            sx={{
              position: "relative",
              width: "100%",
              overflow: "hidden",
              paddingLeft: "11px",
              display: "flex",
              justifyContent: "center",
            }}>
            {/* Left Icon Button */}
            {!uptoTabeltScreen && canGoLeft && (
              <IconButton
                onClick={handlePrev}
                sx={{
                  backgroundColor: "black",
                  width: "15px",
                  height: "25px",
                  color: "white",
                  position: "absolute",
                  left: "0px",
                  top: "50%",
                  transform: "translateY(-50%)",
                  zIndex: 2,
                  "&:disabled": {
                    backgroundColor: "grey",
                    color: "white",
                  },
                  "&:hover": {
                    backgroundColor: "black",
                  },
                }}>
                <ArrowLeftIcon />
              </IconButton>
            )}
            <Box
              sx={{
                display: "flex",
                justifyContent: "flex-start",
                alignItems: "center",
                transition: "transform 0.3s ease-in-out",
                width: "100%",
                columnGap: "0.1rem",
                transform: `translateX(-${currentIndex * 175}px)`,
              }}>
              {portfolioList.map((card, index) => (
                <Box
                  key={index}
                  sx={{
                    flexShrink: 0,
                    width: uptoTabeltScreen ? 320 : 160,
                    padding: "0 8px",
                    maxWidth: "173px",
                    minWidth: "173px",
                  }}>
                  <Card
                    sx={{
                      borderRadius: "16px",
                      border: "1px solid #CBCBCB",
                      width: { xs: "100%", sm: "100%", md: "320px" },
                      background: "#FBFFFB",
                      alignItems: "center",
                      justifyContent: "center",
                    }}>
                    <StyledCard
                      sx={{
                        alignItems: "center",
                        height: "110px !important",
                        width: "165px !important",
                        padding: "0px 10px !important",
                      }}>
                      <CardContentWrapper
                        sx={{
                          padding: "16px 0px",
                        }}>
                        <Typography
                          sx={{
                            color: "#36423C",
                            fontSize: "15px",
                            fontWeight: 700,
                            lineHeight: "30px",
                            display: "flex",
                            alignItems: "center",
                          }}>
                          <StarRating rating={Number(card.rating)} />
                        </Typography>
                        <Typography
                          variant='body2'
                          sx={{
                            my: uptoTabeltScreen ? 1 : 0.5,
                            fontStyle: "italic",
                            color: "#36423C",
                            fontSize: !uptoLaptopScreen ? "11px" : "14px",
                            fontWeight: 600,
                            lineHeight: !uptoLaptopScreen ? "15px" : "30px",
                          }}>
                          {card.content}
                        </Typography>
                      </CardContentWrapper>
                    </StyledCard>
                    <Typography
                      sx={{
                        color: "#CBCBCB",
                        fontSize: !uptoLaptopScreen ? "8.5px" : "12px",
                        fontWeight: 400,
                        textTransform: "capitalize",
                        textAlign: "right",
                        padding: "0px 10px",
                        lineHeight: !uptoLaptopScreen ? "30px" : "56px",
                      }}>
                      {card.service} | {card.date}
                    </Typography>
                  </Card>
                </Box>
              ))}
            </Box>
            {/* Right Icon Button */}
            {!uptoTabeltScreen && canGoRight && (
              <IconButton
                onClick={handleNext}
                sx={{
                  backgroundColor: "black",
                  width: "15px",
                  height: "25px",
                  color: "white",
                  position: "absolute",
                  right: "0px",
                  top: "50%",
                  transform: "translateY(-50%)",
                  zIndex: 2,
                  "&:disabled": {
                    backgroundColor: "grey",
                  },
                  "&:hover": {
                    backgroundColor: "black",
                  },
                }}>
                <ArrowRightIcon />
              </IconButton>
            )}
          </Box>
        )}
      </Grid>

      <Box
        sx={{
          columnGap: "30px",
          display: "flex",
          flexDirection: uptoTabeltScreen ? "row" : "column",
          px: uptoLaptopScreen ? 10 : mobileS || mobile ? 4 : 2,
          alignItems: "center",
          justifyContent: "center",
        }}>
        <img
          style={{
            width: !uptoTabeltScreen ? "150px" : "auto",
          }}
          src={customerStasificationBadge}
          alt='Customer Stasification Badge'
        />

        <Box>
          <CustomerStasificationTitle>
            HELLO FIXY guarantees customer satisfaction.
          </CustomerStasificationTitle>
          <CustomerStasificationSubTitle>
            We aim to achieve customer contentment. Let us know,{" "}
          </CustomerStasificationSubTitle>
          <CustomerStasificationSubscriptTitle>
            if you are not happy with our work.
          </CustomerStasificationSubscriptTitle>
        </Box>
        {desktop && (
          <img
            src={artMasco}
            alt='CoverImage'
            style={{
              height: "300px",
              marginTop: "4rem",
              paddingTop: "4rem",
              paddingRight: "2rem",
              alignItems: "flex-end",
            }}
          />
        )}
      </Box>

      <Box sx={{ border: 0.5, borderColor: "white" }}></Box>

      <Grid
        container
        sx={{
          background: "#1F261F",
          color: "#fff",
          marginTop: "1rem",
          paddingLeft: uptoLaptopScreen ? "10rem" : "1.5rem",
          paddingRight: uptoLaptopScreen ? "10rem" : "1.5rem",
          // paddingBottom: "1rem",
          width: "100%",
        }}>
        <Grid
          item
          xs={12}
          md={8}
          sx={{
            paddingTop: uptoTabeltScreen ? "3rem" : "1rem",
            paddingBottom: uptoLaptopScreen ? "3rem" : isExpanded ? ".5rem" : "0rem",
            justifyContent: "center",
            alignItems: "center",
          }}>
          <FooterTitle
            sx={{ justifyContent: { xs: "space-between", md: "none" } }}
            onClick={toggleFooter}>
            <Typography>POPULAR SERVICES</Typography>
            <Typography
              component={"span"}
              sx={{
                visibility: { xs: "visible", md: "hidden" },
                cursor: "pointer",
                fontSize: "20px",
                display: "contents",
              }}>
              {isExpanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}
            </Typography>
          </FooterTitle>

          <Box
            sx={{
              overflow: "hidden",
              height:
                isExpanded && !uptoTabeltScreen
                  ? `263px`
                  : uptoTabeltScreen
                  ? "auto"
                  : "0px",
              transition: "height 0.3s ease",
              marginTop: "10px"
            }}>
            <Grid
              container
              sx={{
                columnGap: "20px",
                width: allScreens ? "100%" : "100%",
              }}
              xs={12}
              md={12}>
              <Grid item xs={12} md={3}>
                <FooterText
                  onClick={() => {
                    dispatch(
                      setSearchService("Commercial/Residential Laundry Service")
                    )
                    navigate("/all-services")
                  }}>
                  Laundry Services
                </FooterText>
                <FooterText
                  onClick={() => {
                    dispatch(
                      setSearchService(
                        "Commercial/Residential Security Service"
                      )
                    )
                    navigate("/all-services")
                  }}>
                  Security Services
                </FooterText>
                <FooterText
                  onClick={() => {
                    dispatch(setSearchService("Plumbing/Elecrical work"))
                    navigate("/all-services")
                  }}>
                  Plumbing/Electrical work
                </FooterText>                
              </Grid>
              <Grid item xs={12} md={4}>
                <FooterText
                  onClick={() => {
                    dispatch(setSearchService("Pest Control"))
                    navigate("/all-services")
                  }}>
                  Pest Control
                </FooterText>
                <FooterText
                  onClick={() => {
                    dispatch(setSearchService("AC/Electrical Appliances"))
                    navigate("/all-services")
                  }}>
                  AC/Electrical appliances
                </FooterText>
                <FooterText
                  onClick={() => {
                    dispatch(setSearchService("White washing"))
                    navigate("/all-services")
                  }}>
                  Whitewashing/Painting
                </FooterText>
              </Grid>
              <Grid item xs={12} md={3}>
                <FooterText
                  onClick={() => {
                    dispatch(setSearchService("Modular kitchen"))
                    navigate("/all-services")
                  }}>
                  Modular Kitchen
                </FooterText>
                <FooterText
                  onClick={() => {
                    dispatch(setSearchService("House Renovation"))
                    navigate("/all-services")
                  }}>
                  House Renovation
                </FooterText>
                <FooterText
                  onClick={() => {
                    dispatch(setSearchService("Event Management/Catering"))
                    navigate("/all-services")
                  }}>
                  Event Management
                </FooterText>
              </Grid>
            </Grid>
          </Box>
        </Grid>

        <Grid
          item
          xs={12}
          md={4}
          sx={{
            textAlign: "left",
            paddingTop: uptoTabeltScreen ? "3.7rem" : 0,
            paddingBottom: !uptoTabeltScreen ? ".5rem" : "1rem",
            marginTop: !uptoTabeltScreen ? isExpanded ? "5px" : "0" : "20px",
            display: "flex",
            columnGap: "20px",
            flexWrap: uptoTabeltScreen ? "none" : "wrap",
          }}>
          <Grid item xs={12}>
            <Typography
              sx={{
                color: "#fff",
                fontSize: "12px",
                fontWeight: 400,
                lineHeight: "30px",
                display: "flex",
                alignItems: "center",
                px: uptoTabeltScreen ? 1 : 0,
              }}>
              Phone Numbers : {` ${phoneNumber1}  /  ${phoneNumber2}`}
            </Typography>
            {/* <span style={{
            alignItems: "center",
            paddingLeft: uptoTabeltScreen ? "8px" : 0,
            color: "#fff",
            fontSize: "12px",
            fontWeight: 400,
            lineHeight: "30px",
          }}></span> */}
            <Typography
              sx={{
                color: "#fff",
                fontSize: "12px",
                fontWeight: 400,
                lineHeight: !uptoTabeltScreen ? "20px" : "30px",
                display: "flex",
                alignItems: "center",
                px: uptoTabeltScreen ? 1 : 0,
              }}>
              {`Customer Support : ${email}`}
            </Typography>
          </Grid>
        </Grid>
      </Grid>
      <Grid
        container
        sx={{
          background: "#1F261F",
          color: "#fff",
          paddingLeft: uptoLaptopScreen ? "10rem" : "1.5rem",
          paddingRight: uptoLaptopScreen ? "10rem" : "1.5rem",
          paddingBottom: "1rem",
          paddingTop: ".5rem",
          width: "100%",
          justifyContent: "space-between",
        }}>
        <Typography
          sx={{
            color: "#fff",
            fontSize: "12px",
            fontWeight: 400,
            lineHeight: !uptoTabeltScreen ? "20px" : "30px",
            display: "flex",
            alignItems: "center",
            // px: uptoTabeltScreen ? 1 : 0,
          }}>
          {`Address : ${Address}`}
        </Typography>

        <Typography
          sx={{
            color: "#fff",
            fontSize: "12px",
            fontWeight: 400,
            lineHeight: !uptoTabeltScreen ? "20px" : "30px",
            display: "flex",
            alignItems: "center",
            whiteSpace: "nowrap",
            // paddingLeft:"8px",
          }}>
          {/* <span style={{ fontWeight: 700, paddingRight: "10px" }}> */}
          <span style={{ fontWeight: 700 }}>Photo credits :</span>
          <span>Pexel and unsplash</span>
        </Typography>
      </Grid>
      <Grid container sx={{ background: "#2A2F2B", color: "#fff" }}>
        <Grid
          item
          xs={12}
          md={12}
          sx={{
            pl: uptoTabeltScreen ? "10rem" : "1rem",
            pr: uptoTabeltScreen ? "10rem" : "1rem",
            pt: 1,
            pb: 1,
            display: "flex",
            justifyContent: uptoTabeltScreen ? "space-between" : "center",
          }}>
          <Typography
            sx={{
              color: "#fff",
              fontSize: allScreens ? "12px" : "10px",
              fontWeight: 400,
              lineHeight: "30px",
              display: "flex",
              alignItems: "center",
              pr: 3,
            }}>
            HelloFixy@{new Date().getFullYear()}
          </Typography>
          <Typography
            sx={{
              color: "#fff",
              fontSize: allScreens ? "12px" : "10px",
              fontWeight: 400,
              lineHeight: "30px",
              display: "flex",
              alignItems: "center",
            }}>
            Terms of use | Privacy policy | Sitemap
          </Typography>
        </Grid>
      </Grid>
    </Container>
  )
}
