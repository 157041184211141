import {
  Box,
  Button,
  Card,
  CardContent,
  Grid,
  styled,
  Typography,
  useMediaQuery,
} from "@mui/material"
import { useNavigate } from "react-router-dom"
import { useEffect, useState } from "react"
import { useSelector } from "react-redux"
import { RootState } from "../../store"
import { toast } from "react-toastify"
import ServiceCardMore from "./service-card-new"
import AOS from "aos"
import "aos/dist/aos.css"

const DummyBG = require("../../assets/Dummy-image-bg.svg").default

interface Service {
  id: string
  name: string
  short_description: string
  description: string
  image_url: string
}

const ServiceCard = (props: any) => {
  const apiEndpoint = process.env.REACT_APP_LOGIN_API_ENDPOINT

  const {
    selectedService,
    setAddService,
    setSelectedServiceId,
    handleOpen,
    setSelectedService,
    activeCategory,
  } = props

  const isAuthenticated = sessionStorage.getItem("isAuthenticated")
  const role = sessionStorage.getItem("role")
  const [services, setServices] = useState<Service[]>([])
  const bookingDetails = useSelector((state: RootState) => state.bookingDetail)

  useEffect(() => {
    fetchServices()
  }, [selectedService])

  useEffect(() => {
    AOS.init({
      duration: 1000,
    })
  }, [])

  const StyledCardDetails = styled(Box)`
    width: 100%;
    display: flex;
    padding: 20px;
    align-items: flex-start;
  `

  const StyledCard = styled(Box)`
  width: 100%;
  display: flex;
  padding: 20px;
  align-items: flex-start;
  @media (max-width: 1023px) and (min-width: 760px) {
    flex-direction: column;
    align-items: flex-start;
  }
  @media (max-width: 767px) {
    display: block;
    padding: 0;
    font-size: 14px;
  }
`;

const CardContentWrapper = styled(CardContent)`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  align-items: flex-start;
  padding-top: 0;
  padding-bottom: 0;
  @media (max-width: 1023px) and (min-width: 760px) {
    align-items: flex-start;
    text-align: left;
    padding: 0;
  }
  @media (max-width: 768px) {
    width: 100%;
  }
`;

  const ViewDetailsLink = styled(Button)`
    text-align: left;
    padding: 0;
    align-items: flex-start;
    text-transform: capitalize;
  `

  const ActionButton = styled(Button)`
    text-align: left;
    height: 48px;
    color: #ffffff;
    background: var(--primary);
    font-size: 14px;
    font: Kufam;
    font-weight: 600;
    border-radius: 30px;
    margin-top: 15px;
    padding: 8px 24px;
    &:hover {
      background: var(--hover); /* Change the background color on hover */
    }

    &:active {
      background: var(--primary); /* Change the background color on click */
    }
    @media (min-width: 767px) {
      padding: 10px 30px;
      font-size: 16px;
    }
  `

  const fetchServices = async () => {
    try {
      let url = `${apiEndpoint}/services`
      if (activeCategory) {
        url = `${apiEndpoint}/services/${activeCategory?.id}`
      } else {
        url = `${apiEndpoint}/services`
      }

      const response = await fetch(url, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      })
      const data = await response.json()

      if (data && data.results) {
        let serviceData: Service[] = data.results
        const filteredServieList = serviceData.filter(
          (service) => service.id !== selectedService.id
        )

        const randomElements = pickRandomElements(filteredServieList, 3)
        setServices(randomElements)
      } else {
        throw new Error("No services found")
      }
    } catch (error) {
      console.error("Error fetching Services:", error)
    }
  }

  function shuffleArray(array: Service[]) {
    for (let i = array.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1))
      ;[array[i], array[j]] = [array[j], array[i]]
    }
  }
  function pickRandomElements(array: Service[], count: number) {
    // Clone the array to avoid modifying the original array
    const shuffledArray = [...array]

    // Shuffle the cloned array
    shuffleArray(shuffledArray)

    // Pick the first 'count' elements
    const randomElements = shuffledArray.slice(0, count)

    return randomElements
  }
  const isMobileScreen = useMediaQuery("(min-width: 768px)")
  return (
    <Box width={"100%"} data-aos='fade-right'>
      <Card
        sx={{
          width: "100%",
          boxShadow:
            "0px -10px 30px rgba(0, 0, 0, 0.2), 0px 10px 15px rgba(0, 0, 0, 0.1)",
          transition: "all 0.3s ease-in-out",
        }}>
        <StyledCard>
          <img
            src={
              selectedService.image_url ? selectedService.image_url : DummyBG
            }
            alt=''
            style={{
              width: isMobileScreen ? "300px" : "250px",
              height: isMobileScreen ? "300px" : "200px",
              padding: isMobileScreen ? "0px" : "16px 0 0 16px",
            }}
          />
          <CardContentWrapper style={{ height: "auto" }}>
            <Typography
              sx={{
                color: "#36423C",
                fontSize: "24px",
                fontWeight: 700,
                lineHeight: "30px",
                padding: "15px 0px",
              }}>
              {selectedService.name.replace("/", " / ")}
              <Typography
                sx={{
                  mt: 1,
                  color: "#36628B",
                  fontSize: isMobileScreen ? "16px" : "14px",
                  fontWeight: 400,
                  lineHeight: isMobileScreen ? "30px" : "20px",
                  padding: "0px",
                }}>
                {selectedService?.price && `Price: ${selectedService?.price}`}
              </Typography>
            </Typography>
            <Typography
              variant='body2'
              sx={{
                color: "#36423C",
                fontSize: isMobileScreen ? "14px" : "11px",
                fontWeight: 400,
                lineHeight: isMobileScreen ? "30px" : "20px",
              }}>
              {selectedService.description}
            </Typography>
            {role !== "vendor" && (
              <ActionButton
                onClick={() => {
                  if (isAuthenticated) {
                    const idList = bookingDetails?.service_list?.map(
                      (service) => service.id
                    )
                    if (
                      idList &&
                      selectedService &&
                      idList.includes(selectedService.id)
                    ) {
                      toast.warning(`Service already selected`, {
                        position: "top-right",
                        autoClose: 5000,
                      })
                    } else {
                      setAddService(true)
                      setSelectedServiceId(selectedService.id)
                    }
                  } else {
                    sessionStorage.setItem(
                      "service",
                      JSON.stringify(selectedService)
                    )
                    handleOpen()
                  }
                }}>
                Book this service
              </ActionButton>
            )}
          </CardContentWrapper>
        </StyledCard>
      </Card>
      <Typography
        sx={{
          mt: 5,
          color: "#36423C",
          fontSize: "24px",
          fontWeight: 700,
          lineHeight: "30px",
        }}>
        View our other services
      </Typography>

      <Grid
        container
        spacing={2}
        sx={{
          mt: 3,
          display: "flex",
          justifyContent: {
            xs: "center",
            sm: "center",
            md: "center",
            lg: "flex-start",
          },
        }}>
        {services.map((service, index) => (
          <ServiceCardMore
            service={service}
            setSelectedService={setSelectedService}
          />
        ))}
      </Grid>
    </Box>
  )
}

export default ServiceCard
