import * as React from "react";
import Box from "@mui/material/Box";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import StepContent from "@mui/material/StepContent";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import { StepperType } from "../../../types/StepperType";

type Props = {
  steps: Array<StepperType>;
  rejectRequest: (value: boolean) => void;
  acceptRequest: (value: boolean) => void;
  acceptQuotation: (value: boolean) => void;
  viewQuotation: (value: boolean) => void;
};

export default function VerticalStepper({
  steps,
  acceptRequest,
  acceptQuotation,
  viewQuotation,
  rejectRequest,
}: Props) {
  let role = sessionStorage.getItem("role");

  const filteredSteps = steps.filter(
    (step) => step.roles && role && step.roles.includes(role)
  );

  return (
    <Box sx={{ maxWidth: 900 }}>
      <Stepper orientation="vertical">
        {filteredSteps.map((step, index) => {
          return (
            <Step key={index} active={true}>
              <StepLabel StepIconProps={{ icon: "" }}>
                <Typography
                  variant="caption"
                  sx={{
                    fontSize: "16px",
                    color: "var(--secondary)",
                    fontWeight: 600,
                    cursor: "pointer",
                  }}
                >
                  {step.label}
                </Typography>
              </StepLabel>
              <StepContent>
                <Typography>{step.description}</Typography>
                <Box>
                  <div>
                    {role === "user" &&
                      index === 0 &&
                      step.text === "Quotation Received" && (
                        <div>
                          <Button
                            sx={{
                              mt: 2,
                              mr: 1,
                              borderRadius: 9999,
                              fontSize: 12,
                              height: 30,
                            }}
                            variant="outlined"
                            onClick={() => {
                              viewQuotation(true);
                            }}
                          >
                            View Quotation
                          </Button>
                          <Button
                            sx={{
                              mt: 2,
                              mr: 1,
                              borderRadius: 9999,
                              fontSize: 12,
                              height: 30,
                            }}
                            onClick={() => {
                              acceptQuotation(true);
                            }}
                            variant="outlined"
                          >
                            Accept Quotation
                          </Button>
                        </div>
                      )}
                    {role === "vendor" &&
                      index === 0 &&
                      step.text === "Request" && (
                        <div>
                          <Button
                            sx={{
                              mt: 2,
                              mr: 1,
                              borderRadius: 9999,
                              fontSize: 12,
                              height: 30,
                            }}
                            onClick={() => rejectRequest(true)}
                            variant="outlined"
                          >
                            Reject the request
                          </Button>
                          <Button
                            sx={{
                              mt: 2,
                              mr: 1,
                              borderRadius: 9999,
                              fontSize: 12,
                              height: 30,
                            }}
                            onClick={() => acceptRequest(true)}
                            variant="outlined"
                          >
                            Accept the request
                          </Button>
                        </div>
                      )}
                  </div>
                </Box>
              </StepContent>
            </Step>
          );
        })}
      </Stepper>
    </Box>
  );
}
